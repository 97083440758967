import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Login from "./component/pages/Login/index.tsx";
import Signup from "./component/pages/Signup/index.tsx";
import Plan from "./component/pages/Plan/index.tsx";
import Index from './component/pages/Index/index.tsx';

export default function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Index />
    },
    {
      path: "/login",
      element: <Login />
    },
    {
      path: "/signup",
      element: <Signup />
    },
    {
      path: "pricing",
      element: <Plan />
    },
  ])

  return (
    <React.StrictMode>
      <RouterProvider router={router}/>
    </React.StrictMode>
  )
}