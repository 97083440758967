import React, {useState} from 'react';

function Login() {
  /* Title */
  document.title = "Trill - Login to your account"

  //values state for input
  const [values, setValues] = useState({
    email: "",
    username: "",
  });

  //show password state
  const [passwordShown, setPasswordShow] = useState(false);

  //toggle password event
  const togglePassword = () => {
    setPasswordShow(!passwordShown);
  };

  return (
    <div>
        <div className='grid grid-cols-1 h-screen w-full'>
          <div className='sm:bg-slate-100 bg-blue-700 flex flex-col justify-center'>
            <form className='max-w-[500px] w-full mx-auto bg-blue-700 p-8 px-8 rounded-lg'>
              <a href="/">
                <img src="https://trillfront.s3.ap-southeast-1.amazonaws.com/img/TRILL.png" alt="" className='w-36 mx-auto'/>
              </a>

              <div className='flex flex-col py-2'>
                <label htmlFor="email" className='text-white'>Email</label>
                <input type="email" name='email' id='email' placeholder='your@example.com' className='rounded-lg bg-slate-200 mt-2 p-2 focus:border-gray-800 focus:bg-slate-100 focus:outline-none placeholder:text-slate-600' onChange={(e) => setValues({ ...values,[e.target.name]:e.target.value})}/>
              </div>

              <div className='flex flex-col py-2'>
                <label htmlFor="password" className='text-white'>Password <button type='button'><i className={passwordShown ? "bi bi-eye-fill pl-1 h-5 w-5" : "bi bi-eye-slash-fill pl-1 h-5 w-5"} onClick={togglePassword}></i></button></label>
                <input type={passwordShown ? "text" : "password"} name='password' id='password' placeholder='Password' className='rounded-lg bg-slate-200 mt-2 p-2 focus:border-gray-800 focus:bg-slate-100 focus:outline-none placeholder:text-slate-600' onChange={(e) => setValues({ ...values,[e.target.name]:e.target.value})}/>
              </div>

              <div className='flex justify-between text-white py-2'>
                <p className='flex items-center select-none'><input type="checkbox" className='mr-2'/>Remember me</p>
                <a href='/'>Forgot Password?</a>
              </div>

              <button type='submit' className='w-full mt-2 mb-5 py-2 text-blue-800 bg-blue-200 hover:bg-blue-300'>Sign In</button>

              <div className='flex text-md text-white pt-2'>
                <a href="./signup">Don&apos;t have an account? Create one.</a>
              </div>
            </form>
          </div>
        </div>
    </div>
  )
}

export default Login