import React, { useState } from 'react';

function Header() {
    const [nav, setNav] = useState(false);
    const [productNav, setProductNav] = useState(false);

    const navHandler = () => {
        setNav(!nav)
    };

    const productNavHandler = () => {
        setProductNav(!productNav)
    };

    return (
        <header className='border-b'>
            {/* 
            <nav className='container flex items-center mx-auto max-w-8xl bg-white py-6 mt-1 sm:mt-6 sm:px-7 px-5'>
                <div className='py-1'>
                    <img src={Trill} alt='' className='lg:w-32 w-24'/>
                </div>
                <ul className='hidden md:flex md:flex-1 justify-end items-center gap-12 text-slate-600 uppercase text-sm'>
                    <li className='cursor-pointer font-semibold'>Products</li>
                    <li className='cursor-pointer font-semibold'>Solutions</li>
                    <li className='cursor-pointer font-semibold'>Resources</li>
                    <li className='cursor-pointer font-semibold'>Pricing</li>
                    <a href='/login' className='hidden md:flex'>
                        <button type='button' className='bg-blue-700 text-white rounded-full px-7 py-3 uppercase'>Login</button>
                    </a>
                </ul>
                <div className='flex md:hidden flex-1 justify-end'>
                    <a href='/login' className='mr-4'>
                        <button type='button' className='bg-blue-700 text-white rounded-full px-7 py-3 uppercase'>Login</button>
                    </a>
                    <i className="bi bi-list cursor-pointer text-3xl self-center"></i>
                </div>
            </nav> 
            */}
            <div className='container flex items-center mx-auto max-w-8xl bg-white py-5 sm:px-7 px-5 justify-between'>
                <div className='py-1'>
                    <a href="/">
                        <img src="https://trillfront.s3.ap-southeast-1.amazonaws.com/img/Trill.jpg" alt='' className='w-32'/>
                    </a>
                </div>
                <ul className='md:flex hidden'>
                    {/* Dummy div */}
                    <div className={!productNav ? "hidden" : "fixed top-24 left-0 h-full w-full bg-transparent"} onClick={productNavHandler}></div>
                    <li className="p-4 cursor-pointer font-normal select-none z-1" onClick={productNavHandler}>
                        Products &nbsp;{!productNav ? <i className="bi bi-caret-down-fill text-xs text-slate-600"></i> : <i className="bi bi-caret-up-fill rotate-180 text-xs text-slate-600"></i>}
                        <ul className={!productNav ? "px-2 py-2 hidden bg-white rounded-md shadow z-2" : "px-2 py-2 absolute hover:absolute bg-white rounded-md shadow z-2"}>
                            <li className='block pl-3 pr-12 py-3 mt-2 text-sm font-normal bg-transparent rounded-lg md:mt-0 focus:outline-none focus:shadow-outline hover:bg-slate-200'>Photoshop</li>
                            <li className='block pl-3 pr-12 py-3 mt-2 text-sm font-normal bg-transparent rounded-lg md:mt-0 focus:outline-none focus:shadow-outline hover:bg-slate-200'>Illustrator</li>
                            <li className='block pl-3 pr-12 py-3 mt-2 text-sm font-normal bg-transparent rounded-lg md:mt-0 focus:outline-none focus:shadow-outline hover:bg-slate-200'>Premiere Pro</li>
                        </ul>
                    </li>
                    <li className="p-4 cursor-pointer font-normal">Solutions</li>
                    <li className="p-4 cursor-pointer font-normal">Resources</li>
                    <a href="/pricing">
                        <li className="p-4 cursor-pointer font-normal">Pricing</li>
                    </a>
                    <a href='/login' className='hidden md:flex'>
                        <button type='button' className='bg-blue-700 text-white rounded-full px-7 py-3 uppercase'>Login</button>
                    </a>
                </ul>
                <div className='flex md:hidden' onClick={navHandler}>
                    <a href='/login' className='mr-4'>
                        <button type='button' className='bg-blue-700 text-white rounded-full px-7 py-3 uppercase'>Login</button>
                    </a>
                    {!nav ? <i className="bi bi-list cursor-pointer text-3xl self-center"></i> : <i class="bi bi-x cursor-pointer text-3xl self-center"></i>}
                </div>
                {/* Mobile view */}
                <div className={!nav ? "fixed left-[-100%] ease-in-out duration-100" : "fixed px-6 left-0 top-0 w-[80%] h-full border-r bg-white border-r-gray-200 z-[2] ease-in-out duration-300"}>
                    <a href="/">
                        <div className='pt-14'>
                            <img src="https://trillfront.s3.ap-southeast-1.amazonaws.com/img/Trill.jpg" alt='' className='w-32'/>
                        </div>
                    </a>
                    <ul className='pt-24'>
                        <li className="p-4 cursor-pointer font-semibold border-b border-b-gray-200" onClick={productNavHandler}>
                            Products {!productNav ? <i className="bi bi-caret-down-fill text-xs text-slate-600"></i> : <i className="bi bi-caret-up-fill rotate-180 text-xs text-slate-600"></i>}
                            <ul className={!productNav ? "hidden" : ""}>
                                <li className='items-center w-full text-base font-normal group pl-11 pt-4 border-b border-b-gray-200 pb-3'><a href="/">Photoshop</a></li>
                                <li className='items-center w-full text-base font-normal group pl-11 pt-3 border-b border-b-gray-200 pb-3'>Illustrator</li>
                                <li className='items-center w-full text-base font-normal group pl-11 pt-3'>Premiere Pro</li>
                            </ul>
                        </li>
                        <li className="p-4 cursor-pointer font-semibold border-b border-b-gray-200">Solutions</li>
                        <li className="p-4 cursor-pointer font-semibold border-b border-b-gray-200">Resources</li>
                        <a href="/pricing">
                            <li className="p-4 cursor-pointer font-semibold">Pricing</li>
                        </a>
                    </ul>
                </div>
                {/* Dummy div for mobile */}
                <div className={!nav ? "hidden" : "fixed top-20 left-0 h-full w-full bg-transparent"} onClick={navHandler}></div>
            </div>
        </header>
    )
}

export default Header