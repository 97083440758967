import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Signup() {
    /* Title */
    document.title = "Trill - Create an account"

    //values state for input
    const [values, setValues] = useState({
        email: "",
        username: "",
        password: "",
        confirmpassword: "", 
    });

    //show password state
    const [passwordShown, setPasswordShow] = useState(false);

    //toggle password event
    const togglePassword = () => {
        setPasswordShow(!passwordShown);
    };

    //error popup with toast
    const generateError = (err: any) =>
        toast.error(err, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            pauseOnFocusLoss: false,
            draggable: true,
            progress: undefined,
            theme: "dark",
    });

    //handle on submit clicked
    const handleSubmit = async (e: { preventDefault: () => void; }) => {
        e.preventDefault()
        if (document.getElementById("p&p").checked === false) {
            generateError("We need you to agree with our Privacy Policy")
        } else {
            if (values.password === values.confirmpassword) {
                if (values.password.length >= 8) {
                    try {
                        
                    } catch(err) {
        
                    }
                } else {
                    generateError("Password length must be at least 8 characters")
                }
            } else {
                generateError("Password do not match")
            }
        }
    }

    return (
        <div>
        <div className='absolute grid grid-cols-1 h-screen w-full'>
                    <div className='sm:bg-slate-100 bg-blue-700 flex flex-col justify-center'>
                    <ToastContainer toastStyle={{ backgroundColor: "#302f2d"}}/>
                        <div>
                            <form onSubmit={(e) => handleSubmit(e)} className='max-w-[500px] w-full mx-auto bg-blue-700 p-8 px-8 rounded-lg'>
                                <a href="/">
                                    <img src="https://trillfront.s3.ap-southeast-1.amazonaws.com/img/TRILL.png" alt="" className='w-36 mx-auto'/>
                                </a>

                                <div className='flex flex-col py-2'>
                                    <label htmlFor="email" className='text-white'>Email</label>
                                    <input type="email" name='email' id='email' placeholder='you@example.com' className='rounded-lg bg-slate-200 mt-2 p-2 focus:border-gray-800 focus:bg-slate-100 focus:outline-none placeholder:text-slate-600' onChange={(e) => setValues({ ...values,[e.target.name]:e.target.value})}/>
                                </div>

                                <div className='flex flex-col py-2'>
                                    <label htmlFor="username" className='text-white'>Username</label>
                                    <input type="text" name='username' id='username' placeholder='Your nickname' className='rounded-lg bg-slate-200 mt-2 p-2 focus:border-gray-800 focus:bg-slate-100 focus:outline-none placeholder:text-slate-600' onChange={(e) => setValues({ ...values,[e.target.name]:e.target.value})}/>
                                </div>

                                <div className='flex flex-col py-2'>
                                    <label htmlFor="password" className='text-white'>Password <button type='button'><i className={passwordShown ? "bi bi-eye-fill pl-1 h-5 w-5" : "bi bi-eye-slash-fill pl-1 h-5 w-5"} onClick={togglePassword}></i></button></label>
                                    <input type={passwordShown ? "text" : "password"} name='password' id='password' placeholder='Password' className='rounded-lg bg-slate-200 mt-2 p-2 focus:border-gray-800 focus:bg-slate-100 placeholder:text-slate-600' onChange={(e) => setValues({ ...values,[e.target.name]:e.target.value})}/>
                                </div>

                                <div className='flex flex-col py-2'>
                                    <label htmlFor="confirmpassword" className='text-white'>Confirm Password</label>
                                    <input type={passwordShown ? "text" : "password"} name='confirmpassword' id='confirmpassword' placeholder='Confirm Password' className='rounded-lg bg-slate-200 mt-2 p-2 focus:border-gray-800 focus:bg-slate-100 focus:outline-none placeholder:text-slate-600' onChange={(e) => setValues({ ...values,[e.target.name]:e.target.value})}/>
                                </div> 

                                <div className='flex justify-between text-white py-2'>
                                    <p className='flex items-center select-none'><input type="checkbox" id="p&p" className='mr-2'/>I agree to <a className="text-orange-400" target='_blank' rel='noreferrer' href='https://www.freeprivacypolicy.com/blog/sample-privacy-policy-template/'>&nbsp;Privacy Policy</a></p>
                                </div>

                                <button type='submit' className='w-full mt-2 mb-5 py-2 text-blue-800 bg-blue-200 hover:bg-blue-300'>Sign In</button>

                                <div className='flex text-md text-white pt-2'>
                                    <a href="../login">Already have an account? Just login.</a>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
        </div>
    )
}

export default Signup