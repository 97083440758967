import React from 'react'
import Header from '../../Header';
import Footer from '../../Footer';
import Newsletter from '../../Newsletter';

import illustrationImage from '../../../data/illustrationsImage';

function Index() {
  /* Title */
  document.title = "Trill - Advanced platform for Company"

  /* Select Random Image */
  const randomHeroImage = illustrationImage[Math.floor(Math.random() * illustrationImage.length)];

  return (
    <div>
        {/* Header */}
        <div className='z-10 sticky top-0 bg-white'>
            <Header />
        </div>

        {/* 1st Hero */}
        <section className='relative'>
          <div className='container flex flex-col lg:flex-row items-center gap-12 my-16 lg:my-28 lg:mb-32 mx-auto px-4'>
            {/* Text Content */}
            <div className='flex flex-1 flex-col items-center lg:items-start'>
              <h2 className='text-3xl md:text-4xl lg:text-5xl text-center lg:text-left mb-6 font-medium text-slate-700'>Advanced platform for <span className='text-blue-700'>Company</span></h2>
              <p className='text-lg text-center lg:text-left text-black mb-6'>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent imperdiet mauris at feugiat sagittis
              </p>
              <div className='flex justify-center flex-wrap gap-6'>
                <a href="/signup">
                  <button type='button' className='bg-blue-700 hover:bg-blue-800 duration-100 text-white rounded-full px-6 pt-3 pb-4 font-normal'>Sign Up For Free</button>
                </a>
                <a href="/pricing">
                  <button type='button' className='bg-white text-blue-700 rounded-full px-4 pt-3 pb-4 font-normal'>Plans & Pricing</button>
                </a>
              </div>
            </div>
            {/* Image Content */}
            <div className='flex justify-center flex-1 mb-10 md:mb-16 lg:mb-0 z-[1]'>
              <img src={randomHeroImage} alt="" className='w-5/6 h-5/6 sm:w-2/3 sm:h-2/3 md:w-full md:h-full'/>
            </div>
          </div>
        </section>

        {/* Features */}
        <section className='pb-16 mb-16'>
          <div className='mx-auto max-w-7xl px-8 md:px-6'>
            <div className='mb-5 sm:mb-10'>
              <span className='font-medium text-blue-700'>Our Features</span>
              <h1 className='text-2xl font-bold text-slate-700 sm:text-3xl'>Provide Our Features</h1>
            </div>
            <div className='md:flex md:justify-between md:gap-6 xl:gap-10'>
              <div className='mb-5 max-h-[600px] overflow-hidden rounded-lg md:mb-0 md:w-5/12'>
                <img src="https://trillfront.s3.ap-southeast-1.amazonaws.com/img/features.png" alt="" className='h-full scale-125 sm:w-full sm:object-cover'/>
              </div>
              <div className='md:w-7/12'>
                <div className='mb-16 flex flex-col'>
                  <p className='mb-1 text-slate-500'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent imperdiet mauris at feugiat sagittis</p>
                  <p className='mb-10 text-slate-500'>Lorem ipsum dolor sit amet. Qui eius fuga non iure quaerat et ratione omnis sit ipsa veritatis ut dolor cumque. Non natus provident eos vitae quisquam eos galisum corporis id minus dignissimos.</p>
                  <a href="/signup">
                    <button className='w-full rounded-md bg-blue-700 px-8 py-2.5 font-semibold text-white shadow-md shadow-blue-700/20 hover:bg-blue-800 duration-100 md:w-max'>
                      Get Started
                    </button>
                  </a>
                </div>
                <div className='grid gap-6 sm:grid-cols-2 lg:grid-cols-3'>
                  <div className='flex flex-col items-center justify-center rounded-xl bg-white px-4 py-8 shadow-lg'>
                    <img className='mb-3 w-16' src="https://trillfront.s3.ap-southeast-1.amazonaws.com/img/icon+(1).png" alt="" />
                    <h3 className='text-lg font-bold text-slate-600'>Web Design</h3>
                    <a href="/" className='cursor-pointer text-sm text-blue-700'>Learn More</a>
                  </div>
                  <div className='flex flex-col items-center justify-center rounded-xl bg-white px-4 py-8 shadow-lg'>
                    <img className='mb-3 w-16' src="https://trillfront.s3.ap-southeast-1.amazonaws.com/img/icon+(2).png" alt="" />
                    <h3 className='text-lg font-bold text-slate-600'>Automation</h3>
                    <a href="/" className='cursor-pointer text-sm text-blue-700'>Learn More</a>
                  </div>
                  <div className='flex flex-col items-center justify-center rounded-xl bg-white px-4 py-8 shadow-lg'>
                    <img className='mb-3 w-16' src="https://trillfront.s3.ap-southeast-1.amazonaws.com/img/icon+(3).png" alt="" />
                    <h3 className='text-lg font-bold text-slate-600'>Infographic</h3>
                    <a href="/" className='cursor-pointer text-sm text-blue-700'>Learn More</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Trusted */}
        <section className='mx-auto max-w-7xl px-8 py-5 mb-10 shadow-lg shadow-gray-300 outline outline-slate-100'>
          <div className='flex flex-col md:flex-row md:justify-between items-center'>
            <span className='text-lg md:text-base lg:text-lg font-medium text-slate-800'>Trusted by global company</span>
            <div className='flex gap-x-6 sm:gap-x-10 md:gap-x-14 p-2 md:p-0'>
              <img src="https://trillfront.s3.ap-southeast-1.amazonaws.com/img/google-font.svg" alt="" className='w-auto h-14 md:h-[70px] lg:h-20'/>
              <img src="https://trillfront.s3.ap-southeast-1.amazonaws.com/img/facebook-font.svg" alt="" className='w-auto h-14 md:h-[70px] lg:h-20'/>
              <img src="https://trillfront.s3.ap-southeast-1.amazonaws.com/img/corsair-font.svg" alt="" className='w-auto h-14 md:h-[70px] lg:h-20'/>
              <img src="https://trillfront.s3.ap-southeast-1.amazonaws.com/img/paypal-font.svg" alt="" className='w-auto h-14 md:h-[70px] lg:h-20'/>
            </div>
          </div>
        </section>

        {/* Worldwide usage */}
        <section className='py-4 md:py-12 md:pt-24'>
          <div className='mx-auto max-w-7xl px-2 md:px-6 relative'>
            <img src="https://trillfront.s3.ap-southeast-1.amazonaws.com/img/world.svg" alt="" />
            <div>
              <h1 className='absolute text-white outline outline-blue-300 bg-blue-700 py-4 px-6 rounded-md uppercase text-center text-xl font-medium md:text-4xl md:font-bold top-1/3 left-1/2 -translate-x-1/2'>Available for Worldwide</h1>
            </div>
          </div>
        </section>

        {/* Information */}
        <section className='py-16 mt-10 md:mt-44 md:mb-16'>
          <div className='mx-auto max-w-7xl px-8 md:px-6'>
            <div className='md:flex md:justify-between md:gap-6 xl:gap-10'>
              <div className='md:w-7/12'>
                <div className='mb-16 flex flex-col'>
                  <h1 className='text-3xl font-semibold mb-6 text-slate-800 md:pt-[13%]'>Get more customer for your business</h1>
                  <p className='text-slate-700'>Lorem ipsum dolor sit amet. Qui eius fuga non iure quaerat et ratione omnis sit ipsa veritatis ut dolor cumque. Non natus provident eos vitae quisquam eos galisum corporis id minus dignissimos.</p>
                </div>
              </div>
              <div className='mb-5 mx-h-[600px] overflow-hidden md:mb-0 md:w-5/12'>
                <img src="https://trillfront.s3.ap-southeast-1.amazonaws.com/img/infosvg.svg" alt="" className='h-full sm:w-full sm:object-cover'/>
              </div>
            </div>
          </div>
        </section>

        {/* Taught section */}
        <section className='mx-auto grid max-w-[1400px] lg:grid-cols-3 gap-16 sm:gap-20 lg:gap-28 pt-12 pb-20 px-14 sm:px-20 md:px-24'>
          <div className='flex flex-col relative border border-slate-50 shadow-2xl border-b-blue-700 border-b-4'>
            <p className='text-gray-400 pl-4 pt-4 text-xl font-semibold'>01</p>
            <h1 className='text-slate-700 text-3xl sm:text-2xl lg:text-4xl font-bold pl-8 py-6'>Consumer Insights</h1>
            <p className='text-slate-500 px-8 py-6 font-semibold'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent imperdiet mauris at feugiat sagittis</p>
          </div>
          <div className='flex flex-col relative border border-slate-50 shadow-2xl border-b-blue-700 border-b-4'>
            <p className='text-gray-400 pl-4 pt-4 text-xl font-semibold'>02</p>
            <h1 className='text-slate-700 text-3xl sm:text-2xl lg:text-4xl font-bold pl-8 py-6'>Emerging Ideas</h1>
            <p className='text-slate-500 px-8 py-6 font-semibold'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent imperdiet mauris at feugiat sagittis</p>
          </div><div className='flex flex-col relative border border-slate-50 shadow-2xl border-b-blue-700 border-b-4'>
            <p className='text-gray-400 pl-4 pt-4 text-xl font-semibold'>03</p>
            <h1 className='text-slate-700 text-3xl sm:text-2xl lg:text-4xl font-bold pl-8 py-6'>Tought Leadership</h1>
            <p className='text-slate-500 px-8 py-6 font-semibold'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent imperdiet mauris at feugiat sagittis</p>
          </div>
        </section>

        {/* Newsletter Hero */}
        <section className='bg-blue-700'>
          <Newsletter />
        </section>

        {/* Last Hero */}
        <section className='relative'>
          <div className='container flex-col items-center gap-12 mt-12 mx-auto'>
            {/* Text Content */}
            <div className='flex flex-1 flex-col items-center'>
              <h2 className='text-3xl md:text-4xl lg:text-5xl text-center mb-6 font-bold tracking-wider text-slate-800'>Getting excited<span className='text-blue-700'>?</span></h2>
              <p className='text-lg text-black text-center mb-6'>
                Pay, play around then add your team later.
              </p>
              <div className='flex justify-center flex-wrap gap-6 mb-2'>
                <a href="/pricing">
                  <button type='button' className='bg-blue-700 hover:bg-blue-800 duration-100 text-white rounded-xl px-10 pt-2 pb-3 font-normal'>Buy Now</button>
                </a>
                <a href="/signup">
                  <button type='button' className='bg-white text-blue-700 rounded-xl px-10 pt-2 pb-3 font-normal'>Sign Up <i className="bi bi-arrow-right-short"></i></button>
                </a>
              </div>
            </div>
            {/* Image Content */}
            <div className='flex justify-center flex-1 mb-10 md-mb:16 z-[1]'>
              <img src="https://www.notion.so/cdn-cgi/image/format=auto,width=750,quality=100/front-static/pages/home/notion-parade.png" alt="" className='w-5/6 h-5/6 sm:w-2/3 sm:h-2/3 lg:w-1/2 lg:h-1/2'/>
            </div>
          </div>
        </section>

        {/* Footer */}
        <div className='bg-blue-700'>
            <Footer />
        </div>
    </div>
  )
}

export default Index