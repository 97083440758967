export const pricingPlans = [
    {
        p_id: 1,
        title: 'Personal',
        price: 26.99,
        currency: 'USD',
        frequency: '/month',
        description: 'The essentials to provide your best work for clients. ',
        features: [
            '1 user',
            'Basic function',
            '500 requests / day'
        ],
        cta: 'Buy now',
        mostPopular: false
    },
    {
        p_id: 2,
        title: 'Pro',
        price: 45,
        currency: 'USD',
        frequency: '/month',
        description: 'The best plans for every company that want to start their business.',
        features: [
            '10 user',
            'Advanced function',
            '1500 requests / day',
            '48-hour suppport response time',
        ],
        cta: 'Buy now',
        mostPopular: true
    },
    {
        p_id: 3,
        title: 'Enterprise',
        price: 115,
        currency: 'USD',
        frequency: '/month',
        description: 'The most advanced plan to go with a big company',
        features: [
            'Unlimited users',
            'Advanced function',
            'Unlimited requests / day',
            '24-hour support response time',
            'Massive file size support',
        ],
        cta: 'Buy now',
        mostPopular: false
    }
]