import React from 'react'

function Newsletter() {
  return (
    <div className='w-full pt-16 pb-16 text-white px-4'>
      <div className='max-w-screen-2xl mx-auto px-4 grid lg:grid-cols-3'>
        <div className='lg:col-span-2'>
          <h1 className='md:text-4xl sm:text-3xl text-2xl font-bold py-2'>Want to get a new update from us?</h1>
          <p>Sign up to our newsletter and stay up to date.</p>
        </div>
        <div>
          <div className='flex flex-col sm:flex-row items-center justify-between w-full'>
            <input className='p-3  flex w-full rounded-md text-slate-800' type="email" placeholder='Your Email'/>
            <button className='bg-[#eea47fff] text-white font-semibold rounded-md w-[200px] ml-4 my-6 px-6 py-3'>Subscribe</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Newsletter