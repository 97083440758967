import React from 'react';
import Header from '../../Header';
import Footer from '../../Footer';
import { pricingPlans } from '../../../data/plans.js';

function Plan() {
  /* Title */
  document.title = "Trill - Plans & Pricing"

  return (
    <div>
      {/* Header */}
      <div className='z-10 sticky top-0 bg-white'>
        <Header />
      </div>

      {/* Text */}
      <div className='mx-auto max-w-7xl bg-white px-4 pt-16 sm:pt-18 sm:px-6 lg:px-8'>
        <h2 className='text-4xl font-extrabold text-black sm:text-5xl sm:leading-tight sm:tracking-tight'>
          Trill Plans & Pricing
        </h2>
        <p className='mt-4 max-w-3xl text-lg text-slate-500'>
          Choose an affordable plan that's offers with the best features for
          engaging your audience, creating customer loyalty and driving sales.
        </p>
      </div>

      {/* Plans Card */}
      <div className='mx-auto grid max-w-7xl lg:grid-cols-3 gap-12 py-24 px-4 sm:px-6 lg:px-8'>
        {pricingPlans.map((plan) => (
          <div key={plan.title} className="flex flex-col relative rounded-2xl border border-slate-200 p-8 shadow-lg">
            <h3 className='text-lg font-semibold leading-5'>{plan.title}</h3>
            {plan.mostPopular && (
              <p className='absolute top-0 -translate-y-1/2 bg-blue-700 px-3 py-0.5 text-sm font-semibold tracking-wide text-white shadow-md rounded-full'>
                Most Popular
              </p>
            )}
            <p className='mt-4 text-sm leading-6 text-slate-700'>{plan.description}</p>
            <div className='-mx-6 mt-4 bg-slate-50 p-6 rounded-lg'>
              <p className='flex text-sm font-semibold text-slate-500 items-center'>
                <span>{plan.currency}</span>
                <span className='ml-3 text-4xl text-slate-900'>${plan.price}</span>
                <span className='ml-1.5'>{plan.frequency}</span>
              </p>
            </div>
            {/* FEATURES */}
            <ul className='mt-6 space-y-4 flex-1'>
              {plan.features.map((feature) => (
                <li key={feature} className='flex text-sm text-slate-700 leading-6'>
                  <svg
                    className='h-5 w-5 text-cyan-500 shrink-0'
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path 
                      d="M6.70711 10.2929C6.31658 9.90237 5.68342 9.90237 5.29289 10.2929C4.90237 10.6834 4.90237 11.3166
                      5.29289 11.7071L6.70711 10.2929ZM14.0784 7.74974C14.4925 7.38427 14.5319 6.75234 14.1664 6.33827C13.801 
                      5.9242 13.169 5.8848 12.755 6.25026L14.0784 7.74974ZM8.84768 13.8477L8.14057 14.5548C8.38489 14.7991 
                      8.73803 14.9 9.07455 14.8216C9.41106 14.7432 9.68327 14.4967 9.79447 14.1695L8.84768 13.8477ZM9.79447 
                      14.1695C10.6447 11.6685 12.1378 9.46254 14.0784 7.74974L12.755 6.25026C10.5581 8.18922 8.86547
                      10.6883 7.90089 13.5258L9.79447 14.1695ZM5.29289 11.7071L8.14057 14.5548L9.55478 13.1406L6.70711 10.2929L5.29289 
                      11.7071Z"
                      fill="currentColor"
                    
                    />
                  </svg>
                  <span className='ml-3'>{feature}</span>
                </li>
              ))}
            </ul>
            {/* Call to action */}
            <a href="/" className={`block mt-8 px-6 py-4 text-sm font-semibold leading-4 rounded-lg shadow-md text-center
              ${
                plan.mostPopular ? 'text-white bg-blue-700 hover:bg-blue-900 shadow-md' : 'text-blue-700 bg-blue-100 hover:bg-blue-200'
              }
            `}>
                {plan.cta}
            </a>
          </div>
        ))}
      </div>

      {/* Something isn't enough? Add-ons */}

      {/* Frequently asked question */}


      {/* Getting excited? */}
      <div className='container flex-col items-center gap-12 mt-12 mx-auto'>
            {/* Text Content */}
            <div className='flex flex-1 flex-col items-center'>
              <h2 className='text-3xl md:text-4xl lg:text-5xl text-center mb-6 font-bold tracking-wider text-slate-800'>Getting excited<span className='text-blue-700'>?</span></h2>
              <p className='text-lg text-black text-center mb-6'>
                Pay, play around then add your team later.
              </p>
              <div className='flex justify-center flex-wrap gap-6 mb-2'>
                <a href="/pricing">
                  <button type='button' className='bg-blue-700 text-white rounded-xl px-10 pt-2 pb-3 font-normal'>Buy Now</button>
                </a>
                <a href="/signup">
                  <button type='button' className='bg-white text-blue-700 rounded-xl px-10 pt-2 pb-3 font-normal'>Sign Up <i className="bi bi-arrow-right-short"></i></button>
                </a>
              </div>
            </div>
            {/* Image Content */}
            <div className='flex justify-center flex-1 mb-10 md-mb:16 z-[1]'>
              <img src="https://www.notion.so/cdn-cgi/image/format=auto,width=750,quality=100/front-static/pages/home/notion-parade.png" alt="" className='w-5/6 h-5/6 sm:w-2/3 sm:h-2/3 lg:w-1/2 lg:h-1/2'/>
            </div>
          </div>

      {/* Footer */}
      <div className='bg-blue-700'>
        <Footer />
      </div>
    </div>
  )
}

export default Plan