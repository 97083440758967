import React from 'react';

function Footer() {
  return (
    <div className='max-w-[1240px] mx-auto py-16 px-4 grid lg:grid-cols-3 gap-8 text-gray-300'>
        <div>
            <a href="/">
                <img src="https://trillfront.s3.ap-southeast-1.amazonaws.com/img/TRILL.png" alt='' className='w-32'/>
            </a>
            <p className='py-4'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent imperdiet mauris at feugiat sagittis</p>
            <div className='flex md:w-[75%] my-6 justify-between'>
                <a href="https://facebook.com/pishangshedappp" target="_blank" rel='noreferrer'>
                    <i class="bi bi-facebook text-xl text-white"></i>
                </a>
                <a href="https://instagram.com/ahazriq7872" target="_blank" rel='noreferrer'>
                    <i class="bi bi-instagram text-xl text-white"></i>
                </a>
                <a href="https://twitter.com/ahazriq7872" target="_blank" rel='noreferrer'>
                    <i class="bi bi-twitter text-xl text-white"></i>
                </a>
                <a href="https://tiktok.com/@just_akmal" target="_blank" rel='noreferrer'>
                    <i class="bi bi-tiktok text-xl text-white"></i>
                </a>
            </div>
        </div>
        <div className='flex flex-col sm:flex-row justify-between lg:col-span-2'>
            <div className='pb-10 sm:pb-0'>
                <h5 className='font-medium text-gray-100'>About</h5>
                <ul>
                    <li className='py-2 text-sm'>Trill Blog</li>
                    <li className='py-2 text-sm'>Our Team</li>
                    <li className='py-2 text-sm'>Careers</li>
                    <li className='py-2 text-sm'>Partners</li>
                    <li className='py-2 text-sm'>Developer</li>
                </ul>
            </div>
            <div className='pb-10 sm:pb-0'>
                <h6 className='font-medium text-gray-100'>Solutions</h6>
                <ul>
                    <li className='py-2 text-sm'>Enterprise</li>
                    <li className='py-2 text-sm'>Small Business</li>
                    <li className='py-2 text-sm'>Personal Use</li>
                    <li className='py-2 text-sm'>Managers</li>
                    <li className='py-2 text-sm'>Startups</li>
                </ul>
            </div>
            <div className='pb-10 sm:pb-0'>
                <h6 className='font-medium text-gray-100'>Sales</h6>
                <ul>
                    <li className='py-2 text-sm'>1300888333</li>
                    <li className='py-2 text-sm'>Contact Sales</li>
                    <a href="/pricing">
                        <li className='py-2 text-sm'>Plans & Pricing</li>
                    </a>
                    <li className='py-2 text-sm'>Events</li>
                    <li className='py-2 text-sm'>Request a Demo</li>
                </ul>
            </div>
            <div>
                <h6 className='font-medium text-gray-100'>Support</h6>
                <ul>
                    <li className='py-2 text-sm'>Account</li>
                    <li className='py-2 text-sm'>Learning Center</li>
                    <li className='py-2 text-sm'>Feedback</li>
                    <li className='py-2 text-sm'>Accessibility</li>
                    <li className='py-2 text-sm'>Privacy & Policy</li>
                </ul>
            </div>
        </div>
    </div>
  )
}

export default Footer